import React from "react";
import Home from "../components/Home";
import About from "../pages/About";
import Gallery from "./Gallery";
import Testimonial from "./OurWorks";
import OurWork from "./Testimonials";
import VideoGallery from "./VideoGallery";
import ImageGallery from "./ImageGallery";
import WebsiteVisitors from "./WebsiteVisitors";
import WebsiteLiveVisitors from "./WebsiteLiveVisitors";
const MainPage = () => {
  return (
    <div>
      <Home />
      <About />
      <Testimonial showSubHeader ={false} />
      <Gallery showSubHeader={false}/>
      {/* <ImageGallery/> */}
      {/* <VideoGallery/> */}
      <OurWork showSubHeader={false}/>
      {/* <WebsiteVisitors/> */}
      {/* <WebsiteLiveVisitors/> */}
    </div>
  );
};

export default MainPage;
