import React, { useState } from "react";
import fullLogo from "../assests/headerImage/farm1.png";
// import fullLogo from "../assests/headerImage/fulllogo_transparent.png";
import jsPDF from "jspdf";
import Swal from "sweetalert2";

const FormWithPDFPreview = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: ""
  });

  const [pdfBlob, setPdfBlob] = useState(null); // To store the PDF Blob
  const [pdfUrl, setPdfUrl] = useState(null); // To store the Blob URL

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const generatePDF = () => {
    const pdf = new jsPDF();
    const img = new Image();
    img.src = fullLogo;
    img.onload = () => {
      // Draw the background image
      pdf.addImage(img, 'JPEG', 40, 50, 100, 100); // 210x297 is A4 size in mm

      // Add content to PDF
      pdf.setFontSize(22);
      pdf.setFont("helvetica", "semibold");
      pdf.text("Donation Details", 20, 20);
      pdf.setLineWidth(0.5);
      pdf.line(20, 25, 190, 25); // Draw line under title

      // Form Data
      pdf.setFontSize(16);
      pdf.text(`Name: ${formData.name}`, 20, 40);
      pdf.text(`Email: ${formData.email}`, 20, 50);
      pdf.text(`Phone: ${formData.phone}`, 20, 60);
      pdf.text(`Address: ${formData.address}`, 20, 70);
      pdf.text(`Message: ${formData.message}`, 20, 80);

      // Generate the Blob and set the PDF URL
      const pdfOutput = pdf.output('blob');
      setPdfBlob(pdfOutput); // Save the PDF Blob to state
      const url = URL.createObjectURL(pdfOutput);
      setPdfUrl(url); // Set the Blob URL
    };

    img.onerror = () => {
      console.error("Failed to load the image");
      Swal.fire("Error!", "Failed to generate PDF due to image loading error.", "error");
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generatePDF(); // Generate the PDF

    // Show SweetAlert success message
    Swal.fire({
      title: 'Success!',
      text: 'Thank you for your donation! Click OK to preview your PDF..',
      icon: 'success',
      confirmButtonText: 'OK'
    }).then(() => {
      // Show the PDF preview in a modal
      if (pdfUrl) {
        const pdfWindow = window.open(pdfUrl);
        pdfWindow.focus();
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="md:flex">
          {/* Left Section with Image */}
          {/* <div className="hidden md:block md:w-1/2 bg-cover bg-center" style={{ backgroundImage: `url('https://static.vecteezy.com/system/resources/thumbnails/003/731/316/small/web-icon-line-on-white-background-image-for-web-presentation-logo-icon-symbol-free-vector.jpg')`, height: '100%' }}>
          </div> */}

          {/* Right Section with Form */}
          <div className="w-full md:w-1/2 p-8">
            <h2 className="text-3xl font-bold text-gray-900 text-center">Contact Us</h2>
            <p className="text-sm text-gray-500 text-center mb-8">We’d love to hear from you!</p>

            {/* Form */}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Your Name"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Your Email"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Your Phone Number"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Your Address"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Your Message"
                  rows="4"
                ></textarea>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormWithPDFPreview;
