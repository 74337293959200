import React from "react";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header";
import PrivacyPage from "./pages/PrivacyPage";
import RefundPolicy from "./pages/RefundPolicy";
import TermsandCondition from "./pages/TermsandCondition";
import Disclaimer from "./pages/Disclaimer";
import MainPage from "./pages/MainPage";
import AboutRead from "./pages/AboutRead";
import HeaderLinks from "./components/HeaderLinks";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import OurWorks from "./pages/OurWorks";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Donate from "./pages/Donate";
import Faq from "./pages/Faq";
import Feedback from "./pages/Feedback";
import BenefitsOfCow from "./pages/BenefitsOfCow";
import OurProducts from "./pages/OurProducts";
import AnyAmountCow from "./Donation/AnyAmountCow";
import Construction from "./Donation/Construction";
import Food from "./Donation/Food";
import Medicine from "./Donation/Medicine";
import MonthlyCare from "./Donation/MonthlyCare";
// import payment from "./components/Payment"
import Payment from "./components/Payment";
// import HindiCalendar from "./pages/PanchangCalendar";
// import PanchangCalendar from "./pages/PanchangCalendar";
import Test from "./pages/Test";
import Gausevak from "./pages/Gausevak";
import VisitGaushalaForm from "./pages/VisitGaushalaForm ";
import Calendar from "./pages/Calendar";
import Panchang from "./pages/Panchang";
import PaymentSuccess from "./payment/PaymentSuccess";
import PaymentFailure from "./payment/PaymentFailure";
import CartForm from "./pages/CartForm";
import Announcement from "./pages/Announcement";
import ShlokaPopup from "./pages/ShlokaPopup";
import Test1 from "./components/Test1";
import WebsiteVisitors from "./pages/WebsiteVisitors";
import BlogSection from "./pages/BlogSection";
// import Checkout from './Checkout';

// function Success() {
//   return (
//     <div className="flex items-center justify-center h-screen">
//       <h1 className="text-2xl font-bold text-green-500">Payment Successful!</h1>
//     </div>
//   );
// }

// function Failure() {
//   return (
//     <div className="flex items-center justify-center h-screen">
//       <h1 className="text-2xl font-bold text-red-500">Payment Failed!</h1>
//     </div>
//   );
// }


function App() {
  return (
    <Router>
      <div>
        <Header />
        <ShlokaPopup/>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/terms" element={<TermsandCondition />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/more" element={<AboutRead />} />

          <Route path="/headinglinks" element={<HeaderLinks />} />
          {/* <Route path="/donateus" element={<DonateNow />} /> */}
          <Route path="/donate" element={<Donate />} />
          <Route path="/login" element={<Payment />} />

          <Route path="/aboutus" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/testimonial" element={<Testimonials />} />
          <Route path="/ourworks" element={<OurWorks />} />
          <Route path="/ourproducts" element={<OurProducts />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/benefits" element={<BenefitsOfCow />} />
          <Route path="/aboutDetail" element={<AboutRead />} />

          <Route path="/anyamountcow" element={<AnyAmountCow />} />
          <Route path="/construction" element={<Construction />} />
          <Route path="/food" element={<Food />} />
          <Route path="/medicine" element={<Medicine />} />
          <Route path="/monthlycare" element={<MonthlyCare />} />


          {/* <Route path="/hindicalendar" element={<PanchangCalendar/>} /> */}
          <Route path="/gausevak" element={<Gausevak />} />
          <Route path="/visitgaushala" element={<VisitGaushalaForm />} />
          <Route path="/test" element={<Test />} />

          <Route path="/hindicalendar" element={<Calendar />} />
          <Route path="/announcements" element={<Announcement />} />

          <Route path="/panchang/:date" element={<Panchang />} />
          <Route path="/cartform" element={<CartForm />} />


          {/* <Route path="/payment" element={<Checkout />} /> */}
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />
          <Route path="/test1" element={<Test1 />} />
          <Route path="/blogsection" element={<BlogSection />} />

          <Route path="/websitevisitors" element={<WebsiteVisitors />} />
          

          



        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
