import React, { useEffect, useState } from 'react';

const VisitorCounter = () => {
    const [visitors, setVisitors] = useState(0);
    const [displayCount, setDisplayCount] = useState(1); // Start from 1 for animation

    useEffect(() => {
        const fetchVisitorCount = async () => {
            try {
                const response = await fetch('http://localhost:8000/visitors');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setVisitors(data.count);
                animateCounter(data.count); // Start animation to actual count
            } catch (error) {
                console.error('Error fetching visitor count:', error);
            }
        };

        const incrementVisitorCount = async () => {
            try {
                await fetch('http://localhost:8000/visitors', {
                    method: 'POST',
                });
                fetchVisitorCount(); // Fetch the updated count after incrementing
            } catch (error) {
                console.error('Error incrementing visitor count:', error);
            }
        };

        incrementVisitorCount(); // Increment the count when component mounts
    }, []);

    // Function to animate the counter
    const animateCounter = (finalCount) => {
        let count = 1; // Start from 1
        const interval = setInterval(() => {
            setDisplayCount(count);
            if (count >= finalCount) {
                clearInterval(interval); // Stop animation when reaching the final count
            }
            count += 1; // Increment count
        }, 5); // Adjust speed of animation here
    };
    
    return (
        <div className="flex flex-col items-center justify-center bg-[#ffd2b3]  rounded-lg shadow-lg p-8 text-center mx-4 lg:mx-20 mb-4">
            <h1 className="text-4xl font-bold mb-4 text-[#F17951]">Visitor Counter</h1>
            <p className="text-6xl font-extrabold text-[#F17951]">{displayCount}</p>
                <p className="text-lg mt-2">Total Visitors</p>
            </div>
    );
};

export default VisitorCounter;
