import img1 from "../assests/ImageGallery/1.jpg";
import img2 from "../assests/ImageGallery/2.jpg";
import img3 from "../assests/ImageGallery/3.jpg";
import img4 from "../assests/ImageGallery/4.jpg";
import img5 from "../assests/ImageGallery/5.jpg";
import img6 from "../assests/ImageGallery/6.jpg";

function ImageGallery() {
    const images = [img1, img2, img3, img4, img5, img6];

    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {images.map((image, index) => (
                    <div key={index} className="h-auto max-w-full rounded-lg my-8 p-4">
                        <img
                            src={image}
                            alt={`Gallery item ${index + 1}`}
                            className="w-96 rounded-lg"
                        />

                    </div>
                ))}
            </div>
        </div>
    );
}

export default ImageGallery;
