import React, { useEffect, useState } from 'react';
import { socket } from '../components/socket';

const WebsiteLiveVisitors = () => {
    const [liveVisitorCount, setLiveVisitorCount] = useState(0);
    useEffect(() => {

        function countEvent(value) {
            setLiveVisitorCount(value.count);
        }
        socket.on('usercount', countEvent);
        return () => {
            socket.off('usercount', countEvent);
        };
    }, []);

    return (
        <div className="flex items-center justify-center p-4 bg-gray-100 rounded-lg shadow mx-28  md:mt-4 mt-4">
            <h2 className="text-lg font-bold">Live Visitors: </h2>
            <span className="ml-2 text-xl text-blue-600">{liveVisitorCount}</span>
        </div>
    );
};

export default WebsiteLiveVisitors;
