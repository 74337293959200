import React, { useState } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import Swal from 'sweetalert2';
import pdf from "../assests/pdf/CertificateofDonation.pdf";

const Test1 = () => {
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-IN', options);
  };

  const generatePDF = async () => {
    try {
      const currentDate = formatDate(new Date());
      const formattedDateWithPrefix = `on ${currentDate}`;

      // Ensure the amount is always formatted with two decimal places
      const formattedAmount = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2, // Ensures two decimal places
        maximumFractionDigits: 2
      }).format(formData.amount);

      const existingPdfBytes = await fetch(pdf).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Embed the bold font (Helvetica-Bold)
      const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      const normalFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      const page = pdfDoc.getPages()[0];
      const pageWidth = page.getWidth();

      const nameTextWidth = boldFont.widthOfTextAtSize(formData.name, 20);
      const dateTextWidth = normalFont.widthOfTextAtSize(formattedDateWithPrefix, 15);
      const amountTextWidth = boldFont.widthOfTextAtSize(formattedAmount, 20);

      const nameX = (pageWidth - nameTextWidth) / 2;
      const dateX = (pageWidth - dateTextWidth) / 2;
      const amountX = (pageWidth - amountTextWidth) / 2;

      page.drawText(formData.name, {
        x: nameX,
        y: 395,
        size: 20,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      page.drawText(formattedDateWithPrefix, {
        x: dateX,
        y: 375,
        size: 15,
        font: normalFont,
        color: rgb(0, 0, 0),
      });

      page.drawText(formattedAmount, {
        x: amountX,
        y: 320,
        size: 20,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      Swal.fire({
        title: 'Success!',
        text: 'Your form has been submitted successfully. Click OK to download your PDF.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'filled_form.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.error('Error generating PDF:', error);
      Swal.fire('Error!', 'There was an error generating the PDF.', 'error');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generatePDF(); // Generate the PDF with form data
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Your Name"
      />
      <input
        type="number"
        name="amount"
        value={formData.amount}
        onChange={handleChange}
        placeholder="Your Amount"
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default Test1;
